import React, { Fragment,useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Blog = () => {
  useEffect(() => {
    document.title = 'Blog | KumbhaaTech Solutions';
    return () => {
        document.title = 'leading software company - KumbhaaTech Solutions';
    };
}, []);
  const blogPosts = [
    {
      id: 1,
      title: "Explore Insights & Ideas with Kumbhaatech Blog",
      date: "June 1, 2024",
      excerpt: "Stay updated with the latest trends, tips, and tricks in technology, business, and more. Dive into our insightful articles crafted to inspire and inform. Let's discover together!",
      image: "assets/images/blog.jpg",
      link: ""
    },
    {
      id: 2,
      title: "Join Our Team and Shape the Future with Kumbhaatech Solutions",
      date: "May 25, 2024",
      excerpt: "Discover exciting career opportunities that empower innovation and growth. We're looking for talented individuals who are passionate about technology and driven to make a difference. Your journey starts here.",
      image: "assets/images/blog.jpg",
      link: ""
    },
  
  ];

  return (
    <Fragment>
      <Header/>
    <div className="blog-section">
      <h2 className='blog-heading'>Latest Blog Posts</h2>
      <div className="blog-list">
        {blogPosts.map(post => (
          <div className="blog-post" key={post.id}>
            <div className="blog-image">
              <img src={post.image} alt={post.title} loading='lazy' decoding='async'/>
            </div>
            <div className="blog-details">
              <h3>{post.title}</h3>
              <p className="post-date">{post.date}</p>
              <p className="excerpt">{post.excerpt}</p>
              <br/>
              <Link to={post.link} className='blog-btn'> Read More</Link>
            </div>
           
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </Fragment>
  );
};

export default Blog;
