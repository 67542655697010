import React, { Fragment, useState, useRef ,useEffect} from 'react'
import Header from '../Header';
import Footer from '../Footer';
import SubNavigation from '../OurServices/SubNav.jsx';
import Slider from 'react-slick';
import ContentChoosing from './ContentChoosing.jsx'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slider.css';
import { Link } from 'react-router-dom';
const ContentWriting = () => {
    useEffect(() => {
        document.title = ' Expert Content Writing Services | KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    const [activeTab, setActiveTab] = useState('Tab1');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const cards = [
        {
            image: "assets/images/visibility.webp",
            heading: "Increased Visibility and Reach",
            description: "High-quality content attracts and engages audiences, increasing visibility and reach for brands and businesses.",

        },
        {
            image: "assets/images/search-engine -ranking.webp",
            heading: "Improved Search Engine Rankings",
            description: "Search engines favor fresh, relevant, and high-quality content, leading to improved rankings and visibility in search engine results pages (SERPs).",

        },
        {
            image: "assets/images/branding.jpg",
            heading: "Enhanced Brand Authority and Credibility",
            description: "Consistently producing valuable and informative content helps establish brands as authorities in their field, earning the trust and respect of their audience.",

        },
        {
            image: "assets/images/boosting.webp",
            heading: "Boosted Engagement and Interactions",
            description: "Compelling and engaging content encourages interactions, such as likes, shares, comments, and clicks, driving engagement and building a loyal audience.",

        }
    ];

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };


    return (
        <Fragment>
            <Header />
            <div class="custom-banner">
                <div class="custom-banner-content">
                    <h1>Expert Content Writing Services at Affordable Prices</h1>
                    <p>Enhance your brand's voice with Kumbhaatech Solutions. Our team of experienced writers delivers high-quality, engaging content tailored to your business needs. Get top-notch content writing services without breaking the bank.</p>
                    <br />
                    <Link to='/contact-us' className='abt-btn'>Boost Your Content Today</Link>
                </div>
            </div>
            <section className='subnav'>
                <div className="container" >
                    <div className='row'>
                        <div className="col-lg-12" >

                            <SubNavigation />
                        </div>
                    </div>
                </div>
            </section>
            <section className="web-development-container">
                <div className="container">
                    <div className="row">
                        <div className="web-heading col-lg-9">
                            <h2>Unveiling the Power of Content Writing: Uses, Benefits, and Importance in the Digital Age.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="web-content">
                                <p>In today’s digital era, content is king. Whether it's web pages, blog posts, social media updates, or marketing materials, compelling and engaging content plays a pivotal role in attracting, informing, and engaging audiences. </p>
                                <p>At KumbhaaTech, we understand the significance of high-quality content in driving online success. That's why we provide affordable content writing services tailored to meet the diverse needs of businesses in the market.</p>
                                <Link to='/contact-us' className="btn-learn">Learn More!</Link>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="image-container">
                                <img src="assets/images/sop-writing.webp" alt="Web Development" decoding='async' loading='lazy' />
                                {/* <a href="https://www.freepik.com/free-photo/man-is-using-laptop-books-notebook-top-view_136764075.htm#fromView=search&page=1&position=11&uuid=49bbeaeb-7d63-4fc3-86bb-d8be2c717f58">Image by pvproductions on Freepik</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="home-about-section " id='understanding'>
                <div class="container">
                    <div className=' home-about-heading col-lg-7'>
                        <h2>Kumbhatech Solutions: Your Partner in Affordable and Effective Content Writing</h2>
                        <p>At KumbhaaTech Solutions, exceptional web design and development are within everyone's reach. As a leading software company, we specialize in crafting beautiful, functional websites tailored to your unique needs without breaking the bank. We believe that exceptional web design shouldn’t come with a hefty price tag.</p>
                    </div>
                    <div class="home-about-container ">

                        <div className='home-about-container-logo'>
                            <img src="assets/images/content-writing.webp" alt="Understanding Content Writing" decoding='async' loading='lazy' />
                        </div>
                        <div className='home-about-container-inside'>

                            <p>Content writing involves the creation of written material for various platforms and purposes, with the aim of informing, entertaining, or persuading audiences. From website content and blog posts to articles, case studies, and social media updates, content writing encompasses a wide range of formats and styles tailored to specific audiences and objectives.</p>

                            <button className='main-button'> Get Appoinment Now!</button>
                        </div>

                    </div>
                </div>
            </section>
            <section className="info-section" id="purpose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">The Purpose of Content Writing</h2>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Informing and Educating</h3>
                                    <p>Content writing serves as a valuable resource for audiences seeking information, knowledge, or solutions to their problems.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Engaging and Entertaining</h3>
                                    <p>Compelling and engaging content captures the attention of audiences, keeping them entertained and interested in the message being conveyed.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Building Authority and Trust</h3>
                                    <p>High-quality content establishes the author or brand as an authority in their field, earning the trust and respect of their audience.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Driving Conversions and Sales</h3>
                                    <p>Persuasive and compelling content can influence purchasing decisions, driving conversions and sales for businesses.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">Uses of Content Writing</h2>
                                    <p>Content writing finds applications across various industries and platforms, serving diverse needs and objectives. Some common uses include:</p>
                                </div>
                            </div>
                            <div className="info-card-with-icon-inner">
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="Websites content" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Website Content</h5>
                                        <p>Website content includes landing pages, product descriptions, about pages, and other information aimed at informing and engaging website visitors.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="social media" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Social Media Updates</h5>
                                        <p>Social media updates include posts, tweets, and status updates aimed at engaging followers and driving traffic to websites or other online platforms.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="marketing material" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Marketing Materials</h5>
                                        <p>Marketing materials such as brochures, flyers, and email newsletters use content to promote products, services, or events to target audiences.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="blog post and article" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Blog Posts and Articles</h5>
                                        <p>Blog posts and articles provide valuable information, insights, and updates on topics relevant to the target audience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='benefit-slider-section' id='benefits'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="benifit-heading">
                                <h2>Benefits of Content Writing</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="slider-container">
                                <Slider ref={sliderRef} {...settings}>
                                    {cards.map((card, index) => (
                                        <div className="slider-card" key={index}>
                                            <img src={card.image} alt={card.heading} className="card-image" decoding='async' loading='lazy' />
                                            <h4 className="card-heading">{card.heading}</h4>
                                            <p className="card-description">{card.description}</p>
                                            <Link to='/contact-us' className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link> 
                                        </div>
                                    ))}
                                </Slider>
                                <div className="bottom-container">
                                    <div className="custom-arrows">
                                        <button className="custom-arrow" onClick={handlePrevious}>
                                            &#8249;
                                        </button>
                                        <button className="custom-arrow" onClick={handleNext}>
                                            &#8250;
                                        </button>

                                    </div>
                                    <div className='service-links'>
                                        <Link to='/contact-us'>Connect With Us+</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                      <p>At KumbhaaTech Solutions, we deliver high-quality content that clearly communicates your brand’s message. From engaging blog posts to persuasive web copy, our writers craft content that captivates and converts your audience</p>

                                    </div>
                                 
                                   
                                </div>

                            </div>
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>We believe that every business deserves the opportunity to thrive in the digital world, regardless of their financial constraints.</p>

                                    </div>
                                    <br />
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='tab-section' id='importance'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='page-title'>
                                <h2>Importance of Affordable Content Writing</h2>
                                <p>In today’s competitive market, businesses of all sizes must prioritize cost-effective solutions without compromising quality. Affordable content writing services offer the following advantages.</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-container">
                        <div className='cloud-tab'>
                            <div className="tab-headers">
                                <button
                                    className={`tab-link ${activeTab === 'Tab1' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab1')}
                                >
                                    Cost-Effectiveness <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab2' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab2')}
                                >
                                    Scalability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab3' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab3')}
                                >
                                    Consistency and Reliability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab4' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab4')}
                                >
                                    Competitive Edge<span>+</span>
                                </button>
                            </div>
                            <div id="Tab1" className={`tab-content ${activeTab === 'Tab1' ? 'active' : ''}`}>
                                <p>In today’s digital landscape, businesses of all sizes are increasingly recognizing the value of high-quality content. However, one of the significant challenges many organizations face is the high cost associated with professional content creation. This is where cost-effectiveness becomes crucial. By offering affordable content writing services, businesses can allocate their budgets more efficiently, ensuring that they receive quality content without exceeding their financial limits. </p>
                                <p>Affordable content writing services empower businesses, especially startups and small enterprises, to compete with larger corporations. They allow these businesses to maintain a consistent online presence, engage with their audience, and build brand authority without the hefty price tag. Cost-effective content services ensure that every dollar spent contributes directly to business growth by driving traffic, improving search engine rankings, and enhancing customer engagement.</p>
                                <p>Moreover, cost-effectiveness doesn’t mean compromising on quality. Professional content writers who offer affordable services are adept at delivering high-quality content that resonates with the target audience. They understand the nuances of different industries and can create compelling, relevant content that aligns with the business’s goals. This approach ensures that businesses can achieve their marketing objectives while staying within their budget, making content writing a viable option for companies at all stages of growth.</p>
                            </div>
                            <div id="Tab2" className={`tab-content ${activeTab === 'Tab2' ? 'active' : ''}`}>
                                <p>Scalability is a critical factor in content writing, especially for businesses that aim to grow their online presence. As a business expands, so does the need for more content. Whether it’s blog posts, website copy, social media updates, or email campaigns, the demand for content increases with growth. Scalable content writing services are designed to meet these growing needs efficiently.</p>
                            <p>Scalable content writing services offer flexibility, allowing businesses to increase or decrease their content output based on demand. For example, during peak seasons, a business might require more content to keep up with customer engagement and marketing campaigns. Scalable services can adjust to these needs, ensuring that the business can continue to produce high-quality content without interruption.  </p>
                            <p>Furthermore, scalable content writing services help businesses maintain consistency in their messaging. As the business grows, it’s essential to ensure that all content aligns with the brand’s voice and values. Scalable services ensure that the quality and tone of the content remain consistent, regardless of the volume. This consistency is crucial for building brand trust and loyalty among customers.</p>
                            </div>
                            <div id="Tab3" className={`tab-content ${activeTab === 'Tab3' ? 'active' : ''}`}>
                                <p>Consistency and reliability are the cornerstones of successful content writing. Businesses need to ensure that their content is consistently high-quality and aligns with their brand message. Reliable content writing services provide businesses with the assurance that their content needs will be met on time, every time.</p>
                                <p>Consistent content is essential for maintaining a strong online presence. Regularly updated content keeps the audience engaged, improves SEO rankings, and positions the business as an authority in its industry. Reliable content writing services ensure that businesses can meet their content schedules without delays, helping them maintain a steady flow of communication with their audience.</p>
                           <p>Moreover, consistency in content quality is vital for building brand credibility. When businesses deliver high-quality content consistently, they establish themselves as trustworthy and knowledgeable in their field. Reliable content writing services have experienced writers who understand the importance of maintaining this quality across all pieces of content, ensuring that the business’s reputation remains intact. </p>
                            </div>
                            <div id="Tab4" className={`tab-content ${activeTab === 'Tab4' ? 'active' : ''}`}>
                                <p>In a saturated market, businesses must find ways to stand out from the competition. Content writing services play a pivotal role in providing this competitive edge. High-quality content not only attracts and retains customers but also differentiates a business from its competitors.</p>
                                <p>A competitive edge in content writing is achieved through strategic content creation. Professional content writers understand how to craft content that not only resonates with the target audience but also aligns with the business’s goals. They use SEO strategies, compelling storytelling, and industry insights to create content that positions the business ahead of its competitors.</p>
                           <p>Moreover, a strong content strategy enhances a business’s visibility online. By producing content that ranks well on search engines, businesses can attract more organic traffic, generate leads, and ultimately, increase conversions. Content writing services that focus on delivering a competitive edge ensure that businesses can dominate their niche and achieve sustained growth in a crowded market.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="apply-now" id="approch">
                <div className='apply-image-container'>
                    <div className="container" >
                        <div className='col-lg-6 '>
                            <h2 style={{ paddingTop: '30px' }}>KumbhaaTech’s Approach to Affordable Content Writing</h2>
                            <p >At KumbhaaTech, we understand the importance of cost-effective solutions that deliver results. Our approach to content writing focuses on:</p>
                        </div>
                        <div className="row" >

                            <ContentChoosing />

                        </div>
                    </div>
                </div>
            </section>

            <section className='key-module-bg' style={{ marginTop: "50px" }}>
                <div class="container">
                    <div className='features-courseProject'>
                        <div class="row">
                            <div class="col-lg-6">
                                <h2>Our Relateed Services</h2>
                            </div>
                            <div class="key-modules-container">

                                <div class="key-module">

                                    <h4>Domine registration</h4>
                                    <div class="module-content">
                                        <p>Secure your online presence with hassle-free domain registration services.</p>
                                        <Link to='domine-registration-services' className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link> 
                                    </div>
                                </div>
                                <div class="key-module">

                                    <h4>Web Design</h4>
                                    <div class="module-content">
                                        <p>Create stunning, responsive websites that deliver an excellent user experience and reflect your brand's identity</p>
                                        <Link to='/affordable-website-design-services' className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link> 
                                    </div>
                                </div>

                                <div class="key-module">

                                    <h4>Web Hosting</h4>
                                    <div class="module-content">
                                        <p>Discover our affordable website hosting soluctions designed to meet your neeeds without breaking your budget.</p>
                                        <Link to='/web-hosting-services' className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>
            <section className='fixed-bacground-section'>
                <div className="fixed-background-3">
                    <div className="fixed-background-content">
                        <div className="fixed-background-content-inside">
                            <h2>Ready to Enhance Your Content Strategy?</h2>
                            <p>Take your content to the next level with Kumbhatech Solutions' professional writing services. Contact us today for a free consultation and quote. We'll collaborate closely with you to understand your content needs, craft engaging and informative pieces, and recommend affordable solutions that resonate with your audience. Let our expert writers transform your content into a powerful tool for growth and engagement!.</p>
                            <br />
                            <Link to='/contact-us' className='benift-btn'>Connect With Us</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="conclusion" id='Conclusion'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='conclusion-heading'>conclusion</h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="fist-conclusion-content">
                                <p>In conclusion, content writing plays a crucial role in driving online success for businesses in today’s digital age. From informing and educating audiences to engaging and entertaining them, high-quality content serves as a valuable asset for brands and businesses looking to stand out in a crowded marketplace. </p>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="second-conclusion-content">
                                <p>Affordable content writing services offer businesses a cost-effective solution to their content needs, providing access to high-quality content that drives results without breaking the bank. Contact KumbhaaTech today to learn more about our affordable content writing services.</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='news-letter'>
                <div className="news-letter-container">

                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div>
                                    <h4>Get Started Today!</h4>
                                    <p>Ready to captivate your audience with impactful content? Contact us now for a consultation and quote. Let Kumbhatech Solutions help you achieve your content marketing goals effectively!.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='subscribe-btnbox'>
                                    <Link to='/contact-us'>Connect With Us <span>+</span></Link></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </Fragment>
    )
}

export default ContentWriting;
/* <a href="https://www.freepik.com/free-photo/smiling-brunette-woman-shirt-pointing-with-paper-arrow-up_6516048.htm#fromView=search&page=1&position=41&uuid=48b0562f-693d-4353-9e0b-b0640469f367">Image by drobotdean on Freepik</a> boosed engaemnt */
/* <a href="https://www.freepik.com/free-photo/magnifying-glass-wooden-cubes-plaster-wooden-table_8999415.htm#fromView=search&page=1&position=18&uuid=9ac22484-a4e6-47c9-8f86-3d4242e0945d">Image by 8photo on Freepik</a>incrse visibility
 */