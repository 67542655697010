import React, { useState, useEffect } from 'react';


function CountItem({ label, endpoint, duration }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < endpoint) {
        setCount(prevCount => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, duration / endpoint);

    return () => clearInterval(interval);
  }, [count, endpoint, duration]);

  return (
    <div className="count-item">
     
      <p>{count}&nbsp;<span>+</span></p> 
      <h3>{label}</h3>
    </div>
  );
}

function Count() {
  return (
    <section className="abt-count-section">
      <div className="container">
       <div className='row '>
        <div className='col-lg-6'>
        <h3 className="abt-section-heading">At KumbhaaTech, our commitment to quality and excellence is unwavering. We aim to foster enduring client relationships through exceptional service and unwavering support.</h3>
        </div>
       </div>
        <div className="row align-items-center abt-row">
          <div className='col-lg-7'>
          
            <div className="row justify-content-center">
              <div className="col-md-6">
                <CountItem label="Employees" endpoint={12} duration={6000} />
              </div>
              <div className="col-md-6">
                <CountItem label="Years of Experience" endpoint={8} duration={6000} />
              </div>
              <div className="col-md-6">
                <CountItem label="Clients Served" endpoint={60} duration={6000} />
              </div>
              <div className="col-md-6">
                <CountItem label="Projects Completed" endpoint={60} duration={6000} />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <img src="assets/images/earth-kt.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Count;
