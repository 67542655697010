import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';

const ClientSlider = () => {
  const sliderRef = useRef(null);
  const [progress, setProgress] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    beforeChange: (current, next) => {
      const totalSlides = clients.length;
      const newProgress = ((next + 1) / totalSlides) * 100;
      setProgress(newProgress);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const clients = [
    {
      image: "assets/images/started-web.webp",
      heading: "Starter Websites",
      description: "Ideal for small businesses and startups, our starter websites provide a basic online presence with essential features like contact information, an about us section, and service offerings.",
    },
    {
      image: "assets/images/Business-web.webp",
      heading: "Business Websites",
      description: "Ideal for small businesses and startups, our starter websites provide a basic online presence with essential features like contact information, an about us section, and service offerings.",
    },
    {
      image: "assets/images/Ecommerse-web.webp",
      heading: "E-commerce Websites",
      description: "Ideal for small businesses and startups, our starter websites provide a basic online presence with essential features like contact information, an about us section, and service offerings.",
    },
    {
      image: "assets/images/customized.webp",
      heading: "Custom Websites",
      description: "Ideal for small businesses and startups, our starter websites provide a basic online presence with essential features like contact information, an about us section, and service offerings.",
    },
  ];

  useEffect(() => {
   
    setProgress((1 / clients.length) * 100);
  }, [clients.length]);

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="client-slider">
      <Slider ref={sliderRef} {...settings}>
        {clients.map((client, index) => (
          <div key={index} className="client-card">
            <img src={client.image} alt={client.heading} className="client-image" />
            <div className="client-content">
              <h3>{client.heading}</h3>
              <p>{client.description}</p>
            </div>
          </div>
        ))}
      </Slider>
      <div className="bottom-container">
        <div className="custom-arrows">
          <button className="custom-arrow" onClick={handlePrevious}>
            &#8249;
          </button>
          <button className="custom-arrow" onClick={handleNext}>
            &#8250;
          </button>
        </div>
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ClientSlider;
