import React, { Fragment, useState } from 'react';
import "./SubNav.css";
import { Link } from 'react-scroll';

const SubNavigation = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const handleItemClick = (itemName) => {
    setActiveMenuItem(itemName);
  };

  return (
    <Fragment>
      <div className="fltNavNavigation">
        <ul id="mainNavigation">
          <li className={activeMenuItem === 'understanding' ? 'active' : ''}>
            <Link to='understanding' smooth={true} duration={800} onClick={() => handleItemClick('understanding')}>Service Understanding</Link>
          </li>
          <li className={activeMenuItem === 'purpose ' ? 'active' : ''}>
            <Link to='purpose' smooth={true} duration={800} onClick={() => handleItemClick('purpose ')}>Purpose of Service</Link>
          </li>

          <li className={activeMenuItem === 'benefits' ? 'active' : ''}>
            <Link to='benefits' smooth={true} duration={800} onClick={() => handleItemClick('benefits')}>Benefits Of Service</Link>
          </li>
          <li className={activeMenuItem === 'importance' ? 'active' : ''}>
            <Link to='importance' smooth={true} duration={800} onClick={() => handleItemClick('importance')}>Importance of Service</Link>
          </li>
          <li className={activeMenuItem === 'approch ' ? 'active' : ''}>
            <Link to='approch' smooth={true} duration={800} onClick={() => handleItemClick('approch')}>Our Approach</Link>
          </li>
          <li className={activeMenuItem === 'Conclusion' ? 'active' : ''}>
            <Link to='Conclusion' smooth={true} duration={800} onClick={() => handleItemClick('Conclusion')}>Conclusion</Link>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default SubNavigation;
