import React, { Fragment, useState } from 'react';
import "./OurServices/SubNav.css";
import { Link } from 'react-scroll';

const AboutNavigation= () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const handleItemClick = (itemName) => {
    setActiveMenuItem(itemName);
  };

  return (
    <Fragment>
      <div className="fltNavNavigation about-navigation">
        <ul id="mainNavigation">
          <li className={activeMenuItem === 'number' ? 'active' : ''}>
            <Link to='number' smooth={true} duration={800} onClick={() => handleItemClick('number')}>By Number</Link>
          </li>
          <li className={activeMenuItem === 'why' ? 'active' : ''}>
            <Link to='why' smooth={true} duration={800} onClick={() => handleItemClick('why')}>Why Choose</Link>
          </li>
          <li className={activeMenuItem === 'company ' ? 'active' : ''}>
            <Link to='company' smooth={true} duration={800} onClick={() => handleItemClick('company')}>Our History </Link>
          </li>
          <li className={activeMenuItem === 'value' ? 'active' : ''}>
            <Link to='value' smooth={true} duration={800} onClick={() => handleItemClick('value')}>Our Values</Link>
          </li>
          <li className={activeMenuItem === 'team' ? 'active' : ''}>
            <Link to='team' smooth={true} duration={800} onClick={() => handleItemClick('team')}>Our Team</Link>
          </li>
          <li className={activeMenuItem === 'Conclusion' ? 'active' : ''}>
            <Link to='Conclusion' smooth={true} duration={800} onClick={() => handleItemClick('Conclusion')}>Conclusion</Link>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default  AboutNavigation;
