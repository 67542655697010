import React, { useState, useRef, useEffect } from 'react';

const SopChoosing= () => {
  const ChoosingData = [
    {
      heading: "Affordability",
      description: "We offer competitive pricing plans for SOP writing services, ensuring accessibility and affordability for students of all backgrounds and financial situations.",
    },
    {
      heading: "Quality and Expertise",
      description: "Despite offering cheap solutions, we never compromise on quality or originality. Our team of experienced writers delivers personalized, well-written SOPs that reflect each student's unique background, qualifications, and aspirations.",
    },
    {
      heading: "Customer Support",
      description: "We provide dedicated customer support to assist students with any questions, concerns, or inquiries related to our SOP writing services, ensuring prompt and satisfactory resolution",
    },
    {
      heading: "Timeliness and Reliability",
      description: "We prioritize timeliness and reliability in delivering SOP writing services, ensuring that students receive their SOPs on time, every time, without delays or excuses.",
    }
  ];

  return (
    <div  className="col-lg-6">
    <div className="accordions-new is-first-expanded">
      {ChoosingData.map((item, index) => (
        <AccordionItem key={index} title={item.heading} content={item.description} />
      ))}
    </div>
    </div>
  );
};

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px';
      } else {
        contentRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen]);

  return (
    <article className="accordion">
      <div className="accordion-head" onClick={toggleAccordion}>
        <span>{title}</span>
        <span className={`icon ${isOpen ? 'open' : ''}`}>
          <i className={`fa ${isOpen ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
        </span>
      </div>
      <div
        ref={contentRef}
        className={`accordion-body ${isOpen ? 'open' : ''}`}
        style={{ maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px' }}
      >
        <div className="content">
          <p>{content}</p>
        </div>
      </div>
    </article>
  );
};

export default SopChoosing;
