import React, { Fragment, useState, useRef, useEffect } from 'react'
import Header from '../Header';
import Footer from '../Footer';
import SubNavigation from '../OurServices/SubNav.jsx';
import Slider from 'react-slick';
import MarketingChoosing from './MarketingChoosing.jsx'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slider.css';
import { Link } from 'react-router-dom';
const DigitalMarketing = () => {
    useEffect(() => {
        document.title = 'Strategic Digital Marketing Services | KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    const [activeTab, setActiveTab] = useState('Tab1');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const cards = [
        {
            image: "assets/images/Cost-effectively.webp",
            heading: "Cost-Effectiveness",
            description: "Digital marketing offers a cost-effective alternative to traditional marketing channels, allowing businesses to reach a larger audience at a fraction of the cost.",

        },
        {
            image: "assets/images/measurable.webp",
            heading: "Measurable Results",
            description: "Digital marketing campaigns are highly trackable and measurable, providing businesses with valuable insights into campaign performance, audience engagement, and return on investment (ROI).",

        },
        {
            image: "assets/images/target.jpg",
            heading: "Targeted Audience Reach",
            description: "Digital marketing allows businesses to target specific demographics, interests, and behaviors, ensuring that marketing messages reach the right audience at the right time.",

        },
        {
            image: "assets/images/flexibility.webp",
            heading: "Flexibility and Agility",
            description: "Digital marketing campaigns can be quickly and easily adjusted based on real-time data and insights, allowing businesses to adapt to changing market conditions and consumer preferences.",

        }
    ];

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };


    return (
        <Fragment>
            <Header />
            <div class="custom-banner">
                <div class="custom-banner-content">
                    <h1>Affordable Digital Marketing Services at Kumbhaatech Solutions</h1>
                    <p>Elevate your brand's online presence with Kumbhaatech Solutions. We offer comprehensive digital marketing services designed to boost your visibility, engagement, and conversions. Achieve your marketing goals with expert strategies at a price you can afford.</p>
                    <br />
                    <Link to='/contact-us' className='abt-btn'>Boost Your Marketing Today!</Link>
                </div>
            </div>

            <section className='subnav'>
                <div className="container" >
                    <div className='row'>
                        <div className="col-lg-12" >

                            <SubNavigation />
                        </div>
                    </div>
                </div>
            </section>
            <section className="web-development-container">
                <div className="container">
                    <div className="row">
                        <div className="web-heading col-lg-9">
                            <h2>Unveiling the Power of Digital Marketing: Uses, Benefits, and Importance in Today's Business Landscape.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="web-content">
                                <p>In the fast-paced digital world we live in, traditional marketing strategies alone are no longer sufficient to reach and engage modern consumers. Enter digital marketing – a versatile and dynamic approach to promoting products, services, and brands in the digital realm.</p>
                                <p> At KumbhaaTech, we understand the importance of cost-effective digital marketing solutions. That's why we provide cheap digital marketing services tailored to meet the diverse needs of businesses in the market.</p>
                                <Link to='/contact-us' className="btn-learn">Learn More!</Link>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="image-container">
                                <img src="assets/images/Digital-Marketing-KT.webp" alt="Digital Marketing:" decoding='async' loading='lazy' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="home-about-section " id='understanding'>
        <div class="container">
          <div className=' home-about-heading col-lg-7'>
            <h2>Kumbhatech Solutions: Your Partner in Affordable and Effective Digital Marketing</h2>
            <p>Digital marketing encompasses a wide range of strategies and tactics aimed at promoting brands, products, and services through digital channels such as websites, social media, search engines, email, and mobile apps.</p>
          </div>
          <div class="home-about-container ">

            <div className='home-about-container-logo'>
            <img src="assets/images/Digital-marketing.webp" alt="digital marketing" decoding='async' loading='lazy'/>
            </div>
            <div className='home-about-container-inside'>
              
              <p>From search engine optimization (SEO) and content marketing to social media advertising and email campaigns, digital marketing offers businesses a cost-effective way to reach and engage their target audience.</p>

              <button className='main-button'> Get Appoinment Now!</button>
            </div>

          </div>
        </div>
      </section>
            <section className="info-section" id="purpose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">The Purpose of Digital Marketing</h2>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Increasing Brand Awareness</h3>
                                    <p>Digital marketing helps businesses increase brand visibility and awareness among their target audience, driving recognition and recall.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Driving Website Traffic</h3>
                                    <p>By leveraging SEO, content marketing, and social media, digital marketing drives qualified traffic to websites, increasing the chances of conversions and sales.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Generating Leads and Conversions</h3>
                                    <p>Digital marketing strategies such as email marketing, pay-per-click (PPC) advertising, and social media campaigns help businesses generate leads and drive conversions, ultimately boosting revenue and growth.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Building Customer Relationships</h3>
                                    <p>Through personalized messaging, targeted advertising, and engaging content, digital marketing helps businesses build and nurture relationships with their audience, fostering loyalty and retention.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">Uses of Digital Marketing</h2>
                                    <p>Digital marketing strategies find applications across various industries and objectives, serving diverse needs and goals. Some common uses include:</p>
                                </div>
                            </div>
                            <div className="info-card-with-icon-inner">
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/digital-marketing-icon.png" alt="seo" decoding='async' loading='lazy'/>
                                        </div>
                                        <h5>Search Engine Optimization (SEO)</h5>
                                        <p>SEO improves website visibility and search engine rankings, driving organic traffic and increasing brand visibility.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/digital-marketing-icon.png" alt="content-marketing"decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Content Marketing</h5>
                                        <p>Content marketing involves creating and distributing valuable, relevant content to attract and engage a target audience, driving brand awareness and loyalty.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/digital-marketing-icon.png" alt="Social Media Marketing"decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Social Media Marketing</h5>
                                        <p>Social media marketing utilizes social platforms such as Facebook, Instagram, Twitter, and LinkedIn to reach and engage audiences, drive website traffic, and generate leads.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/digital-marketing-icon.png" alt="Email Marketing"decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Email Marketing</h5>
                                        <p>Email marketing involves sending targeted messages and promotions to subscribers, nurturing leads, and driving conversions through personalized communication.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='benefit-slider-section' id='benefits'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="benifit-heading">
                                <h2>Benefits of Digital Marketing</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="slider-container">
                                <Slider ref={sliderRef} {...settings}>
                                    {cards.map((card, index) => (
                                        <div className="slider-card" key={index}>
                                            <img src={card.image} alt={card.heading} className="card-image" />
                                            <h4 className="card-heading">{card.heading}</h4>
                                            <p className="card-description">{card.description}</p>
                                            <Link to='/contact-us' className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link> 
                                        </div>
                                    ))}
                                </Slider>
                                <div className="bottom-container">
                                    <div className="custom-arrows">
                                        <button className="custom-arrow" onClick={handlePrevious}>
                                            &#8249;
                                        </button>
                                        <button className="custom-arrow" onClick={handleNext}>
                                            &#8250;
                                        </button>

                                    </div>
                                    <div className='service-links'>
                                    <Link to='/contact-us'>Connect With Us+</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>Boost your online presence with KumbhaaTech Solutions’ digital marketing services. We offer SEO, social media management, and email marketing strategies to drive traffic, increase visibility, and grow your customer base.</p>

                                    </div>
                                  
                                </div>

                            </div>
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>We believe that every business deserves the opportunity to thrive in the digital world, regardless of their financial constraints.</p>

                                    </div>
                                    <br />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='tab-section' id='importance'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='page-title'>
                                <h2>Importance of Cheap and best Digital Marketing</h2>
                                <p>In today’s competitive market, businesses of all sizes must prioritize cost-effective solutions without compromising quality. Cheap digital marketing services offer the following advantages:</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-container">
                        <div className='cloud-tab'>
                            <div className="tab-headers">
                                <button
                                    className={`tab-link ${activeTab === 'Tab1' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab1')}
                                >
                                    Affordability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab2' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab2')}
                                >
                                    Scalability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab3' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab3')}
                                >
                                    Competitive Edge <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab4' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab4')}
                                >
                                    High Return on Investment (ROI):<span>+</span>
                                </button>
                            </div>
                            <div id="Tab1" className={`tab-content ${activeTab === 'Tab1' ? 'active' : ''}`}>
                                <p> In today's digital landscape, strategic digital marketing offers a highly cost-effective solution for businesses aiming to maximize their outreach without stretching their budgets. The affordability of digital marketing comes from its ability to provide targeted campaigns that reach specific audiences, minimizing wasted resources. Unlike traditional marketing methods that require significant financial investment in print, TV, or radio ads, digital marketing leverages platforms like social media, email, and search engines to create personalized and impactful campaigns at a fraction of the cost.</p>
                            <p>The pay-per-click (PPC) model, for instance, allows businesses to only pay for actual engagement, ensuring every dollar spent contributes directly to potential leads. Moreover, the scalability of digital marketing tools like Google Ads and social media advertising means businesses can start with small investments and gradually increase spending as they see results. This flexibility is particularly beneficial for small businesses and startups with limited budgets, enabling them to compete with larger companies on a more level playing field.</p>
                            <p>Another aspect that enhances the affordability of digital marketing is the ability to measure and optimize campaigns in real-time. With access to analytics and performance data, businesses can adjust their strategies on the fly, eliminating ineffective tactics and reallocating resources to more successful efforts. This agility not only saves money but also ensures that marketing budgets are spent on activities that yield the highest return.</p>
                            <p>Furthermore, the diverse range of digital marketing channels available means businesses can choose the most cost-effective methods for their specific goals. Whether it's content marketing, email campaigns, or social media ads, businesses have the flexibility to allocate resources to the channels that offer the best value for their investment. In essence, strategic digital marketing empowers businesses to achieve their marketing objectives without the financial burden associated with traditional advertising methods.</p>
                            </div>
                            <div id="Tab2" className={`tab-content ${activeTab === 'Tab2' ? 'active' : ''}`}>
                                <p> One of the most compelling features of strategic digital marketing is its inherent scalability. Digital marketing campaigns can be easily scaled to match the growth and changing needs of a business. Whether a company is just starting out or has already established a solid market presence, digital marketing strategies can be adjusted to accommodate new goals, larger audiences, and expanding product lines.</p>
                            <p>Scalability in digital marketing is facilitated by the use of various tools and platforms that allow businesses to gradually increase their marketing efforts as they grow. For example, businesses can start with a basic social media presence and over time, expand to include paid advertising, influencer partnerships, and content marketing strategies. This incremental approach ensures that marketing efforts grow in line with the business, preventing overspending while maximizing impact.</p>
                            
                            <p>Another key aspect of scalability is the ability to test and refine campaigns on a smaller scale before rolling them out more broadly. A business can start with a limited geographic area or a specific demographic group, gather data, and then scale the campaign based on the insights gained. This data-driven approach minimizes risk and ensures that larger campaigns are more likely to succeed.</p>
                           <p>Additionally, digital marketing platforms such as Google Ads and Facebook Ads offer sophisticated targeting options that can be scaled up as needed. Whether increasing the budget, expanding the target audience, or adding new ad formats, businesses have full control over how and when to scale their efforts. This flexibility ensures that digital marketing remains effective and efficient, even as businesses grow.</p>
                            </div>
                            <div id="Tab3" className={`tab-content ${activeTab === 'Tab3' ? 'active' : ''}`}>
                                <p>In a crowded marketplace, gaining a competitive edge is crucial for any business. Strategic digital marketing provides the tools and strategies necessary to stand out from the competition and capture the attention of potential customers. By leveraging the latest trends, technologies, and techniques, businesses can create marketing campaigns that are not only relevant but also resonate deeply with their target audiences.</p>
                                <p>One of the primary ways digital marketing offers a competitive edge is through precise targeting. Unlike traditional marketing methods, digital marketing allows businesses to target specific demographics, behaviors, and interests, ensuring that their message reaches the right people at the right time. This level of precision not only increases the effectiveness of marketing campaigns but also maximizes return on investment.</p>
                                <p>Furthermore, digital marketing enables businesses to build strong, lasting relationships with their customers. Through content marketing, social media engagement, and email campaigns, businesses can create a loyal customer base that is more likely to choose their products or services over competitors. This ongoing interaction helps to establish a brand’s authority and trustworthiness in the market, further enhancing its competitive position.</p>
                                <p>Innovation is another critical factor in maintaining a competitive edge. Digital marketing allows businesses to quickly adapt to changes in the market and experiment with new strategies. Whether it's adopting the latest social media trend, utilizing artificial intelligence for personalized marketing, or exploring new content formats, staying ahead of the curve ensures that a business remains relevant and appealing to consumers.</p>
                            </div>
                            <div id="Tab4" className={`tab-content ${activeTab === 'Tab4' ? 'active' : ''}`}>
                              <p>One of the most compelling reasons businesses invest in strategic digital marketing is the potential for a high return on investment (ROI). Digital marketing offers measurable and trackable results, allowing businesses to see exactly how their marketing efforts translate into leads, sales, and revenue. This transparency makes it easier to justify marketing spend and optimize strategies to maximize ROI.</p>
                              <p>Digital marketing’s high ROI is largely due to its ability to reach highly targeted audiences. By focusing on specific demographics, interests, and behaviors, businesses can ensure that their marketing messages are seen by people who are most likely to convert. This targeted approach reduces waste and increases the likelihood of generating valuable leads, leading to a higher ROI.</p>
                              <p>Moreover, digital marketing campaigns can be continuously monitored and adjusted in real-time. If a particular ad or campaign isn’t performing as expected, businesses can quickly pivot and try a different approach. This flexibility ensures that marketing budgets are always being used in the most effective way possible, further enhancing ROI.The variety of digital marketing channels also contributes to its high ROI. Businesses can diversify their marketing efforts across multiple platforms—such as social media, search engines, and email—each offering its own unique advantages. This multi-channel approach not only increases reach but also allows businesses to capture leads at different stages of the customer journey, increasing the chances of conversion.</p>
                           <p>Finally, the cost-effectiveness of digital marketing plays a significant role in its high ROI. With lower costs compared to traditional marketing methods and the ability to start small and scale up, businesses can achieve substantial results without a massive upfront investment. The combination of targeted outreach, real-time optimization, and multi-channel strategies makes digital marketing one of the most efficient and effective ways to drive business growth and profitability.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="apply-now" id="approch">
                <div className='apply-image-container'>
                    <div className="container" >
                        <div className='col-lg-6 '>
                            <h2 style={{ paddingTop: '30px' }}>KumbhaaTech’s Approach to Cheap Digital Marketing.</h2>
                            <p>At KumbhaaTech, we understand the importance of cost-effective solutions that deliver results. Our approach to digital marketing focuses on:</p>
                        </div>
                        <div className="row" >

                            <MarketingChoosing />

                        </div>
                    </div>
                </div>
            </section>
            <section className="technologies-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2>Latest Technologies </h2>
                            <p>Digital marketing in social media liken Facebook, Linkedin,X, instagram,google ads both paid and organic. we use tools like canvas premium,youcam premium,photoshop licensed.</p>
                        </div>
                    </div>
                    <div className="technologies-container">
                        <div className="technologies-view">
                            <div className="tech-image-view">
                                <img src="assets/images/canva.png" alt="Digital marketing in social media" decoding='async' loading='lazy'/>
                            </div>
                            <div className="tech-image-view">
                                <img src="assets/images/photoshop.png" alt="Digital marketing in social media" decoding='async' loading='lazy'/>
                            </div>
                            <div className="tech-image-view">
                                <img src="assets/images/youcam.png" alt="Digital marketing in social media" decoding='async' loading='lazy'/>
                            </div>
                            <div className="tech-image-view">
                                <img src="assets/images/linkedin.png" alt="Digital marketing in social media" decoding='async' loading='lazy'/>
                            </div>
                            <div className="tech-image-view">
                                <img src="assets/images/googlead.png" alt="Digital marketing in social media" decoding='async' loading='lazy'/>
                            </div>
                            <div className="tech-image-view">
                                <img src="assets/images/fb.png" alt="Digital marketing in social media" decoding='async' loading='lazy'/>
                            </div>
                            <div className="tech-image-view">
                                <img src="assets/images/instagram.png" alt="Digital marketing in social media" decoding='async' loading='lazy'/>
                            </div>
                           
                            <div className="tech-image-view">
                                <img src="assets/images/twitter.png" alt="Digital marketing in social media" decoding='async' loading='lazy'/>
                            </div>
                             {/* <a href="https://www.freepik.com/icon/twitter_11823292#fromView=keyword&page=1&position=9">Icon by Freepik</a> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className='key-module-bg' style={{ marginTop: "50px" }}>
                <div class="container">
                    <div className='features-courseProject'>
                        <div class="row">
                            <div class="col-lg-6">
                                <h2>Our Relateed Services</h2>
                            </div>
                            <div class="key-modules-container">

                                <div class="key-module">

                                    <h4>Content Writing</h4>
                                    <div class="module-content">
                                        <p>Deliver engaging, SEO-optimized content that captivates your audience and boosts your search engine rankings.</p>
                                        <Link to='affordable-expert-content-writing' className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link> 
                                    </div>
                                </div>
                                <div class="key-module">

                                    <h4>Web Design</h4>
                                    <div class="module-content">
                                        <p>Create stunning, responsive websites that deliver an excellent user experience and reflect your brand's identity</p>
                                        <Link to='/affordable-website-design-services' className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link> 
                                    </div>
                                </div>

                                <div class="key-module">

                                    <h4>Web Hosting</h4>
                                    <div class="module-content">
                                        <p>Discover our affordable website hosting solutions designed to meet your needs without breaking your budget.</p>
                                        <Link to='/web-hosting-services' className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>

            <section className='fixed-bacground-section'>
                <div className="fixed-background-4">
                    <div className="fixed-background-content">
                        <div className="fixed-background-content-inside">
                            <h2>Ready to Get Started with Digital Marketing?</h2>
                            <p>Don't let budget limitations hinder your online growth. Contact Kumbhatech Solutions today for a free consultation and quote. Our expert digital marketers will collaborate closely with you to comprehend your business objectives, tailor effective strategies, and recommend affordable solutions that align with your goals. Discover how our strategic digital marketing services can propel your online presence to new heights!</p>
                           <br/>
                            <Link to='/contact-us' className='benift-btn'>Connect With Us</Link>
                        </div>
                    </div>
                </div>
            </section>


            <section className="conclusion" id='Conclusion'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='conclusion-heading'>conclusion</h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="fist-conclusion-content">
                                <p>In conclusion, digital marketing plays a crucial role in driving online success for businesses in today’s digital age. From increasing brand awareness and driving website traffic to generating leads and conversions, digital marketing offers businesses a cost-effective way to reach and engage their target audience. Cheap digital marketing services provide businesses with an affordable solution to their marketing needs, ensuring that they can compete and thrive in today’s competitive market. Contact KumbhaaTech today to learn more about our cheap digital marketing services and take your business to new heights.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="second-conclusion-content">
                                <p>By choosing KumbhaaTech, you're partnering with a team that prioritizes affordability, quality, and performance, ensuring that your digital marketing campaigns deliver maximum results at minimal cost. Let us help you unlock the full potential of your business with our cheap digital marketing services tailored to meet your unique needs and objectives.</p>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='news-letter'>
                <div className="news-letter-container">

                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div>
                                    <h4>Get in Touch Today!</h4>
                                    <p>Ready to take the next step towards digital excellence? Contact us today for a free consultation and quote. Let Kumbhatech Solutions be your trusted partner in harnessing the power of technology to drive your business forward.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='subscribe-btnbox'>
                                    <Link to='/contact-us'>Connect With Us <span>+</span></Link></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default DigitalMarketing;
/* <a href="https://www.freepik.com/free-photo/measuring-tape-wooden-cubes-with-text-measure_9485735.htm#fromView=search&page=1&position=1&uuid=89f637d9-125c-4aaa-8d91-c81865677a99">Image by 8photo on Freepik</a>measurable reslu */
/* <a href="https://www.freepik.com/free-photo/paper-sheet-with-economy-chart_8175561.htm#fromView=search&page=1&position=48&uuid=89f637d9-125c-4aaa-8d91-c81865677a99">Image by freepik</a> measurable reslt bar */
/* <a href="https://www.freepik.com/free-psd/3d-character-young-woman-business-concept_27305718.htm#fromView=search&page=1&position=2&uuid=6eb2365a-8b37-404b-90cd-c858037d8c50">Image by jcomp on Freepik</a>trageded audience */