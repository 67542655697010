import React, { Fragment, useState ,useEffect} from 'react';
import Header from './Header';
import Footer from './Footer';


function Careers() {
    useEffect(() => {
        document.title = 'Careers | KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    const [showApplyForm, setShowApplyForm] = useState(false);
    const [selectedJob, setSelectedJob] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        resume: ''
    });

    const jobs = [
        {
            title: "SOFTWARE DEVELOPERS",
            description: "We are looking for talented software developers to join our team. You will be responsible for developing high-quality software solutions, collaborating with cross-functional teams, and ensuring code quality and performance.",
        },
        {
            title: "DIGITAL MARKETING ANALYST",
            description: "As a digital marketing analyst, you will analyze market trends, develop marketing strategies, and optimize digital campaigns to drive business growth. Strong analytical skills and a passion for digital marketing are essential for this role.",
        },
        {
            title: "Web Developer",
            description: "Join our team of web developers to build cutting-edge web applications and websites. You will work closely with designers and backend developers to create engaging user experiences and scalable web solutions.",
        },
        {
            title: "CONTENT/TECHNICAL WRITERS",
            description: "We are seeking talented content and technical writers to create compelling content for our websites, blogs, and technical documentation. Strong writing skills and attention to detail are a must.",
        },
        {
            title: "DESIGNERS- UI/UX/VISUAL/GRAPHIC",
            description: "Join our design team to create beautiful and user-friendly interfaces for our web and mobile applications. You will collaborate with cross-functional teams to translate business requirements into stunning design concepts.",
        },
        {
            title: "QA Engineers",
            description: "As a QA engineer, you will be responsible for testing software applications to ensure they meet quality standards. You will develop test plans, execute test cases, and report bugs to help improve product quality.",
        },
        {
            title: "Back end developer",
            description: "Join our backend development team to design and implement scalable and efficient server-side applications. You will work with databases, APIs, and cloud technologies to build robust backend systems.",
        },
        {
            title: "Fullstack developer",
            description: "We are looking for full-stack developers with experience in both frontend and backend technologies. You will work on a variety of projects, from building user interfaces to designing database schemas.",
        },
        {
            title: "Front end developer",
            description: "Join our frontend development team to create responsive and interactive user interfaces for web applications. You will use HTML, CSS, and JavaScript frameworks to bring designs to life.",
        }
    ];

    const handleApplyClick = (job) => {
       /*  setSelectedJob(job);
        setShowApplyForm(true); */
        alert('JOB Expried!')
    };

    const handleCloseForm = () => {
        setShowApplyForm(false);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
    };

    return (
        <Fragment>
            <Header/>
            <section className='top-container-career'>
                <div className="container">
                    <div className='top-career-content'>
                        <h2>Join Our Team and Shape the Future with Kumbhaatech Solutions</h2>
                        <p>Discover exciting career opportunities that empower innovation and growth. We're looking for talented individuals who are passionate about technology and driven to make a difference. Your journey starts here.</p>
                    </div>
                    <div className='top-career-image'>
                       <img src="assets/images/career-kumbhatech.png" alt="" />
                    </div>
                </div>
            </section>
             
            <section className='career-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Some opportunities for you to explore</h1>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="career-container">
                            {jobs.map((job, index) => (
                                <div className="job" key={index}>
                                    <div className="job-content">
                                        <h4>{job.title}</h4>
                                        <p>{job.description}</p>
                                    </div>
                                    <div className='close-bottom'>
                                        <p className='close-job'>Expired</p>
                                        <button className="apply-btn" onClick={() => handleApplyClick(job.title)}>Apply Now</button>
                                    </div>
                                </div>
                            ))}
                           {/*  {showApplyForm && (
                                <div className="apply-form-container">
                                    <div className="apply-form">
                                        <span className="close-btn" onClick={handleCloseForm}>&times;</span>
                                        <h2>Apply for {selectedJob}</h2>
                                        <form onSubmit={handleSubmit}>
                                            <label htmlFor="name">Name:</label>
                                            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required /><br /><br />
                                            <label htmlFor="email">Email:</label>
                                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required /><br /><br />
                                            <label htmlFor="resume">Resume:</label>
                                            <input type="file" id="resume" name="resume" value={formData.resume} onChange={handleChange} required /><br /><br />
                                            <input type="submit" value="Submit" />
                                        </form>
                                    </div>
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
            </section>
            <section className='career-portal'>
               <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='portal-content'>
                            <h2>Can’t find what you’re looking for?</h2>
                            <p>Register on our Candidate Portal and get notified when new roles that match your skills open up.</p>
                        </div>
                        <button className="apply-btn-portal" onClick={() => handleApplyClick()}>Register Here</button>
                    </div>
                </div>
               </div>
            </section>
            <Footer/>
        </Fragment>
    );
}

export default Careers;
