import React, { Fragment, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';

const Project = () => {
  useEffect(() => {
    document.title = 'Our Projects | KumbhaaTech Solutions';
    return () => {
      document.title = 'Leading Software Company - KumbhaaTech Solutions';
    };
  }, []);

  const [selectedFilter, setSelectedFilter] = useState('running');

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  // Placeholder data for demonstration; replace with actual data
  const servicesData = [
    { id: 1, type: 'completed', image: 'assets/project/project-01.webp',},
    { id: 2, type: 'completed', image: 'assets/project/project-02.webp' },
    { id: 3, type: 'completed', image: 'assets/project/project-03.webp' },
    { id: 4, type: 'completed', image: 'assets/project/project-04.webp' },
    { id: 5, type: 'completed', image: 'assets/project/project-05.webp' },
    { id: 6, type: 'completed', image: 'assets/project/project-06.webp' },
    { id: 7, type: 'completed', image: 'assets/project/project-07.webp' },
    { id: 8, type: 'completed', image: 'assets/project/project-08.webp' },
    { id: 9, type: 'completed', image: 'assets/project/project-09.webp' },
    { id: 10, type: 'completed', image: 'assets/project/project-10.webp' },
    { id: 11, type: 'completed', image: 'assets/project/project-11.webp' },
    { id: 12, type: 'running', image: 'assets/project/R-project-01.webp',title:'Hezko',description:'Admin Panel'  },
    { id: 13, type: 'running', image: 'assets/project/R-project-02.webp', title:'Dreamy Drapes',description:' Curtain and Home Decoration Services website' },
    { id: 14, type: 'running', image: 'assets/project/R-project-03.webp', title:'therapy Point',description:'' },
    { id: 15, type: 'running', image: 'assets/project/R-project-04.webp', title:'',description:'' },
    { id: 16, type: 'running', image: 'assets/project/R-project-05.webp',title:'',description:''  },
    { id: 17, type: 'poster', image: 'assets/project/Brochure_MAX_Bi-Fold (1).pdf' },
   
   
    { id: 20, type: 'poster', image: 'assets/project/YG Academy Brochure.pdf' },
    { id: 19, type: 'poster', image: 'assets/project/Company_Profile_MAX version 3 - 26-04-2022 (1).pdf' },
    { id: 21, type: 'poster', image: 'assets/project/Company Profile (1).pdf' },
    { id: 18, type: 'poster', image: 'assets/project/Company profile MFA - Copy (1).pdf' },
    { id: 22, type: 'poster', image: 'assets/project/Hezko Company Profile (1).pdf' },
    
  ];
  const filteredServices = servicesData.filter(service => service.type === selectedFilter);

  const handleViewPdf = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <Fragment>
      <Header />

      <section className="heading-page header-text" id="top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h6>Here are our sampe Projects</h6>
              
            </div>
            <div className="filter-buttons">
              <button className={selectedFilter === 'running' ? 'active' : ''} onClick={() => handleFilterChange('running')}>Running Projects</button>
              <button className={selectedFilter === 'completed' ? 'active' : ''} onClick={() => handleFilterChange('completed')}>Completed Projects</button>
              <button className={selectedFilter === 'poster' ? 'active' : ''} onClick={() => handleFilterChange('poster')}>Posters</button>
            </div>
          </div>
        </div>
      </section>

      <div className="gallery">
        <div className="container">
          {filteredServices.length > 0 ? (
            filteredServices.map(service => (
              <div className="gallery-item" key={service.id}>
                {service.type === 'poster' ? (
                  <div className="pdf-item">
                    <iframe
                      src={service.image + '#view=FitH'}
                      width="300px"
                      height="200px"
                      frameBorder="0"
                      title="PDF Preview"
                    ></iframe>
                    <button onClick={() => handleViewPdf(service.image)}>View PDF</button>
                  </div>
                ) : (
                  <img src={service.image} alt={service.title || 'Project'} />
                )}
              </div>
            ))
          ) : (
            <p>No projects available in this category.</p>
          )}
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};


export default Project;
/* <a href="https://www.freepik.com/free-vector/blank-screen-computer-icon-isolated-white-background_9895249.htm#fromView=search&page=1&position=8&uuid=3a96cb73-8d51-418d-9851-46dd321927f1">Image by brgfx on Freepik</a> */
