import React, { useState, useRef, useEffect } from 'react';

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px';
      } else {
        contentRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen]);

  return (
    <article className="accordion">
      <div className="accordion-head" onClick={toggleAccordion}>
        <span>{title}</span>
        <span className={`icon ${isOpen ? 'open' : ''}`}>
          <i className={`fa ${isOpen ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
        </span>
      </div>
      <div
        ref={contentRef}
        className={`accordion-body ${isOpen ? 'open' : ''}`}
        style={{ maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px' }}
      >
        <div className="content">
          <p style={{color:'black'}}>{content}</p>
        </div>
      </div>
    </article>
  );
};

export default AccordionItem;
