import React, { Fragment } from 'react';
import Header from './Header';
import Footer from './Footer';

const HowWeWork = () => {

  return (
    <Fragment>
      <Header />
      <section className="how-we-banner" id="top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="how-banner-content">
                <h1>How <span>We Work</span></h1>
                <hr className='service-line' />
                <p>At KumbhaaTech, we believe that a structured and transparent process is key to delivering exceptional digital solutions. Our workflow is designed to ensure that every project meets your requirements, stays on schedule, and delivers outstanding results. Here’s a step-by-step overview of how we work:</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="we-work-section">
        <div className="container">
          <div className="row we-work-section-one">
            <div className="col-lg-6">
              <div className="how-we-work-content">
                <h2>1. Requirement Gathering</h2>
                <p>The first step in our process is understanding your needs. We begin with a comprehensive requirement gathering phase where we:</p>
                <ul>
                  <li><span>Conduct Initial Consultations:</span> We engage in detailed discussions to understand your business goals, target audience, and specific project requirements.</li>
                  <li><span>Define Project Scope:</span> We identify the core objectives, functionalities, and deliverables of the project.</li>
                  <li><span>Stakeholder Interviews:</span> We gather insights from key stakeholders to ensure all perspectives are considered.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="how-we-work-images">
                <img src="assets/images/how-we-work-01.webp" alt="Requirement Gathering Image" />
                {/* <a href="https://www.freepik.com/free-photo/business-people-using-tablet-cafe-1_993385.htm#fromView=search&page=1&position=24&uuid=af91a25a-d32d-4fea-ae57-62054538a2f2">Image by katemangostar on Freepik</a> */}
              </div>
            </div>
          </div>
          <div className="row we-work-section-one wrap-reverse">

            <div className="col-lg-6">
              <div className="how-we-work-images">
                <img src="assets/images/how-we-work-02.webp" alt="Documentation (FRS/SRS)" />
                {/* <a href="https://www.freepik.com/free-photo/consulting-with-lawyer_5766393.htm#fromView=search&page=1&position=14&uuid=d05c0c7c-4265-453a-9af5-a932eca984c2">Image by pressfoto on Freepik</a> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="how-we-work-content">
                <h2>2. Documentation (FRS/SRS)</h2>
                <p>Once we have a clear understanding of your requirements, we move on to creating detailed documentation:</p>
                <ul>
                  <li><span>Functional Requirements Specification (FRS): </span> This document outlines the functional aspects of the project, detailing what the system should do.</li>
                  <li><span>Software Requirements Specification (SRS):</span> We identify the core objectives, functionalities, and deliverables of the project.</li>

                </ul>
              </div>
            </div>

          </div>

          <div className="row we-work-section-one">
            <div className="col-lg-6">
              <div className="how-we-work-content">
                <h2>3. Estimation</h2>
                <p>With the requirements clearly defined, we proceed to the estimation phase:</p>
                <ul>
                  <li><span>Resource Estimation: </span> We determine the resources needed, including team members and technologies.</li>
                  <li><span>Time Estimation</span> We estimate the time required for each phase of the project.</li>
                  <li><span>Cost Estimation: </span>We provide a detailed cost estimate to ensure transparency and alignment with your budget.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="how-we-work-images">
                <img src="assets/images/how-we-work-03.webp" alt="Estimation" />
                {/* <a href="https://www.freepik.com/free-photo/closeup-economist-using-calculator-while-going-through-bills-taxes-office_25751795.htm#fromView=search&page=1&position=0&uuid=64f51d48-6f84-4e40-bf42-00fc2d0d5df8">Image by Drazen Zigic on Freepik</a> */}
              </div>
            </div>
          </div>

          <div className="row we-work-section-one wrap-reverse">

            <div className="col-lg-6">
              <div className="how-we-work-images">
                <img src="assets/images/how-we-work-04.webp" alt="Development" />
                {/* <a href="https://www.freepik.com/free-photo/rear-view-programmer-working-all-night-long_5698334.htm#fromView=search&page=1&position=12&uuid=6c96fd95-2942-4e46-819f-688d3cfb4be0">Image by pressfoto on Freepik</a> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="how-we-work-content">
                <h2>4. Development</h2>
                <p>Our skilled development team brings the project to life:</p>
                <ul>
                  <li><span>Project Planning:</span> We create a detailed project plan with milestones and deadlines.</li>
                  <li><span>Agile Development:</span> We follow agile methodologies to ensure flexibility and iterative progress.</li>
                  <li><span>Coding and Implementation:</span> Our developers use the latest technologies and best practices to build robust and scalable solutions.</li>
                </ul>
              </div>
            </div>

          </div>
          <div className="row we-work-section-one">
            <div className="col-lg-6">
              <div className="how-we-work-content">
                <h2>5.Quality Control (QC) Testing</h2>
                <p>Quality is paramount at KumbhaaTech. Our QC testing phase ensures that the final product meets the highest standards:</p>
                <ul>
                  <li><span>Unit Testing: </span>Each component is tested individually to ensure it functions correctly.</li>
                  <li><span>Integration Testing:</span> We test how different components work together.</li>
                  <li><span>System Testing:</span> The complete system is tested in an environment that simulates real-world usage.</li>
                  <li><span>User Acceptance Testing (UAT):</span>We involve you in testing the product to ensure it meets your expectations and requirements.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="how-we-work-images">
                <img src="assets/images/how-we-work-05.webp" alt="Quality Control (QC) Testing" />
                {/* <a href="https://www.freepik.com/free-photo/african-american-businesswoman-talking-worker-woodworking-industrial-facility_26876553.htm#fromView=search&page=1&position=0&uuid=6c96fd95-2942-4e46-819f-688d3cfb4be0">Image by Drazen Zigic on Freepik</a> */}
              </div>
            </div>
          </div>
          <div className="row we-work-section-one wrap-reverse">

            <div className="col-lg-6">
              <div className="how-we-work-images">
                <img src="assets/images/how-we-work-06.webp" alt="Delivery"/>
                {/* <a href="https://www.freepik.com/free-photo/front-view-young-male-courier-red-uniform-cape-holding-paper-food-package-pink-background_11016556.htm#fromView=search&page=1&position=9&uuid=13b9f2f2-c3ae-4b83-9983-43427b346287">Image by KamranAydinov on Freepik</a> */}
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="how-we-work-content">
                <h2>6.Delivery</h2>
                <p>The final step is delivering the project:</p>
                <ul>
                  <li><span>Final Review</span> We conduct a thorough review to ensure everything is in order.</li>
                  <li><span>Client Training: </span> We provide training to your team to ensure they can effectively use the new system.</li>
                  <li><span>Deployment:</span> We deploy the solution in your live environment, ensuring a smooth transition.</li>

                  <li><span>Post-Delivery Support: </span>Our support doesn’t end at delivery. We offer ongoing support and maintenance to ensure your solution remains effective and up-to-date.
                    At KumbhaaTech, our structured approach ensures that every project is delivered on time, within budget, and to your complete satisfaction. Contact us today to learn more about how we can help you achieve your digital goals.
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
<Footer/>
    </Fragment>
  );
}

export default HowWeWork;
