import React, { useState, useRef, useEffect } from 'react';

const TeleChoosing = () => {
  const ChoosingData = [
    {
      heading: "Direct Customer Engagement",
      description: "Telemarketing and telesales allow businesses to engage directly with customers, offering a personalized approach to marketing and sales.",
    },
    {
      heading: "Lead Generation & Qualification",
      description: "Telemarketing helps identify and qualify leads, ensuring that the sales team focuses on prospects who are most likely to convert.",
    },
    {
      heading: "Customer Relationship Building",
      description: "These strategies build and nurture relationships with customers, fostering loyalty and long-term engagement.",
    },
    {
      heading: "Revenue Generation",
      description: "Telesales directly contributes to the bottom line by closing sales and driving revenue through phone-based transactions.",
    },
    {
        heading: "Market Research and Feedback",
        description: "Telemarketing provides valuable insights into customer preferences, pain points, and market trends, helping businesses refine their offerings.",
      }
  ];

  return (
    <div  className="col-lg-6">
    <div className="accordions-new is-first-expanded">
      {ChoosingData.map((item, index) => (
        <AccordionItem key={index} title={item.heading} content={item.description} />
      ))}
    </div>
    </div>
  );
};

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px';
      } else {
        contentRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen]);

  return (
    <article className="accordion">
      <div className="accordion-head" onClick={toggleAccordion}>
        <span>{title}</span>
        <span className={`icon ${isOpen ? 'open' : ''}`}>
          <i className={`fa ${isOpen ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
        </span>
      </div>
      <div
        ref={contentRef}
        className={`accordion-body ${isOpen ? 'open' : ''}`}
        style={{ maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px' }}
      >
        <div className="content">
          <p>{content}</p>
        </div>
      </div>
    </article>
  );
};

export default TeleChoosing;
