
import React, { useState, useRef, useEffect } from 'react';

const SeoChoosing= () => {
  const ChoosingData = [
    {
      heading: "Affordability",
      description: "We offer competitive pricing plans for SEO services, ensuring accessibility and affordability for businesses of all sizes.",
    },
    {
      heading: "Comprehensive Optimization",
      description: "We provide comprehensive SEO services, including website audits, keyword research, on-page optimization, content creation, link building, and performance tracking, to ensure maximum visibility and results.",
    },
    {
      heading: "Customization and Flexibility",
      description: "We tailor our SEO strategies to meet the unique needs and objectives of each client, ensuring that our services align with their business goals and target audience.",
    },
    {
      heading: "Transparency and Reporting",
      description: "We provide transparent reporting and regular performance updates to our clients, keeping them informed about the progress and effectiveness of our SEO efforts.",
    }
  ];

  return (
    <div  className="col-lg-6">
    <div className="accordions-new is-first-expanded">
      {ChoosingData.map((item, index) => (
        <AccordionItem key={index} title={item.heading} content={item.description} />
      ))}
    </div>
    </div>
  );
};

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px';
      } else {
        contentRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen]);

  return (
    <article className="accordion">
      <div className="accordion-head" onClick={toggleAccordion}>
        <span>{title}</span>
        <span className={`icon ${isOpen ? 'open' : ''}`}>
          <i className={`fa ${isOpen ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
        </span>
      </div>
      <div
        ref={contentRef}
        className={`accordion-body ${isOpen ? 'open' : ''}`}
        style={{ maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px' }}
      >
        <div className="content">
          <p>{content}</p>
        </div>
      </div>
    </article>
  );
};

export default SeoChoosing;
