import React, { useState, useRef, useEffect } from 'react';

const HostingChoosing= () => {
  const ChoosingData = [
    {
      heading: "Competitive Pricing",
      description: "We offer competitive pricing plans for web hosting services, ensuring accessibility and affordability for businesses and individuals of all sizes.",
    },
    {
      heading: "Reliable Performance",
      description: "Our hosting services provide reliable performance and high uptime, ensuring that websites remain accessible to users at all times.",
    },
    {
      heading: "Comprehensive Features",
      description: "Despite offering cheap solutions, we never compromise on features. Our hosting plans include essential features such as SSL certificates, firewalls, regular backups, and scalable resources.",
    },
    {
      heading: "Customer Support",
      description: "We provide dedicated customer support to assist users with any technical issues or inquiries related to our web hosting services, ensuring prompt and satisfactory resolution.",
    },{
        heading: "Security and Compliance",
        description: "We prioritize the security and compliance of our hosting services, providing robust security measures and ensuring that websites are protected from cyber threats and data breaches.",
      }
  ];

  return (
    <div  className="col-lg-6">
    <div className="accordions-new is-first-expanded">
      {ChoosingData.map((item, index) => (
        <AccordionItem key={index} title={item.heading} content={item.description} />
      ))}
    </div>
    </div>
  );
};

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px';
      } else {
        contentRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen]);

  return (
    <article className="accordion">
      <div className="accordion-head" onClick={toggleAccordion}>
        <span>{title}</span>
        <span className={`icon ${isOpen ? 'open' : ''}`}>
          <i className={`fa ${isOpen ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
        </span>
      </div>
      <div
        ref={contentRef}
        className={`accordion-body ${isOpen ? 'open' : ''}`}
        style={{ maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px' }}
      >
        <div className="content">
          <p>{content}</p>
        </div>
      </div>
    </article>
  );
};

export default HostingChoosing;
