import React, { Fragment, useState } from 'react'
import Header from './Header';
import Footer from './Footer';
import BannerSlider from './BannerSlider';
import ServicesSlider from './ServicesSlider';
import Accordion from './Accordion.jsx';
import { Link } from 'react-router-dom';
import Testimonial from './Testimonial.jsx'

import WebSlider from './WebSlider';
import ClientSlider from './ClientSlider.jsx';

const Home = () => {


  return (
    <Fragment>

      <Header />
      <BannerSlider />
      <section className="services-over" id="meetings">
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2>Services Overview</h2>
            </div>
          </div>
          <ServicesSlider />
        </div>


      </section>

      <section class="home-about-section ">
        <div class="container">
          <div className=' home-about-heading col-lg-7'>
            <h2>Kumbhatech Solutions: Your Partner in Affordable and Effective Web Design and Development</h2>
            <p>At KumbhaaTech Solutions, exceptional web design and development are within everyone's reach. As a leading software company, we specialize in crafting beautiful, functional websites tailored to your unique needs without breaking the bank. We believe that exceptional web design shouldn’t come with a hefty price tag.</p>
          </div>
          <div class="home-about-container ">

            <div className='home-about-container-logo'>
              <img src="assets/images/kumbha logo copy.png" alt="  Partner in Affordable" loading='lazy'/>
            </div>
            <div className='home-about-container-inside'>
              <h3>Your Partner in Technological Growth</h3>
              <p>Whether you’re a small business, a startup, or an individual looking to establish an online presence, our team is here to bring your vision to life with innovative design and cutting-edge technology. Our commitment to quality and customer satisfaction sets us apart in the industry.</p>


            </div>

          </div>
        </div>
      </section>

      <section className="apply-now" id="apply">
        <div className='apply-image-container'>
          <div className="container" >
            <div className='col-lg-6 '>
              <h2 style={{ paddingTop: '30px' }}>Why Choose Kumbhatech Solutions for Your Website Needs?</h2>
            </div>
            <div className="row" >

              <Accordion />

            </div>
          </div>
        </div>
      </section>
      <section className="upcoming-meetings" id="meetings">
        <div className="container">
          <div className='affortable-design col-lg-8'>
            <h2>Our Affordable Website Design Services</h2>
            <p>"Discover our affordable website design services at Kumbhatech Solutions. We specialize in creating sleek, user-friendly websites that enhance your online presence. Whether you're a startup or an established business, our expert team delivers tailored solutions to meet your needs. Contact us today to elevate your digital footprint!"</p>
          </div>
          <br />
          <WebSlider />
        </div>
      </section>

      <section className='key-module-bg '>
        <div class="container">
          <div className='features-courseProject'>
            <div class="row">
              <div class=" webSlider-heading col-lg-8">
                <h2>Benefits of Choosing Kumbhatech Solutions:</h2>
                <p>Choosing Kumbhatech Solutions ensures tailored, industry-specific expertise, reliable quality, and agile solutions, empowering businesses to excel in a digitally transformed landscape with innovation and trust.</p>
              </div>
              <div class="key-modules-container">
                <div class="key-module">
                  <div class="key-image-container">
                    <img src="assets/images/Cost -effectly-red.png" alt="Static" class="static-image" loading='lazy' decoding='async' />
                    <img src="assets/images/Cost -effectly.gif" alt="Animated" class="animated-image" loading='lazy' decoding='async' />
                    {/* <a href="https://www.flaticon.com/free-animated-icons/development" title="development animated icons">Development animated icons created by Freepik - Flaticon</a> */}
                  </div>
                  <h4>Cost-Effective</h4>
                  <div class="module-content">
                    <p>We offer cheap web design services that are significantly lower than traditional web design agencies, yet deliver exceptional quality and results.</p>
                    <Link to='/contact-us' className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
                  </div>
                </div>
                <div class="key-module">
                  <div class="key-image-container">
                    <img src="assets/images/quality-red.png" alt="Static" class="static-image" loading='lazy' decoding='async' />
                    <img src="assets/images/quality.gif" alt="Animated" class="animated-image" loading='lazy' decoding='async' />
                    {/*<a href="https://www.flaticon.com/free-animated-icons/quality" title="quality animated icons">Quality animated icons created by Freepik - Flaticon</a>  */}
                  </div>
                  <h4>Quality & Efficiency</h4>
                  <div class="module-content">
                    <p>We leverage efficient processes and the latest technologies to deliver high-quality websites within your budget and timeframe.</p>
                    <Link to='/contact-us' className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
                  </div>
                </div>
                <div class="key-module">
                  <div class="key-image-container">
                    <img src="assets/images/Team-red.png" alt="Static" class="static-image" loading='lazy' decoding='async' />
                    <img src="assets/images/team.gif" alt="Animated" class="animated-image" loading='lazy' decoding='async' />
                    {/* <a href="https://www.flaticon.com/free-animated-icons/reunion" title="reunion animated icons">Reunion animated icons created by Freepik - Flaticon</a> */}
                  </div>
                  <h4>Experienced Team</h4>
                  <div class="module-content">
                    <p>Our team of skilled designers and developers possesses extensive experience in creating websites that are not only visually appealing but also strategically designed to achieve your business goals.</p>
                    <Link to='/contact-us' className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
                  </div>
                </div>
                <div class="key-module">
                  <div class="key-image-container">
                    <img src="assets/images/Scalability-red.png" alt="Static" class="static-image" loading='lazy' decoding='async' />
                    <img src="assets/images/Scalability.gif" alt="Animated" class="animated-image" loading='lazy' decoding='async' />
                    {/* <a href="https://www.flaticon.com/free-animated-icons/full-screen" title="full screen animated icons">Full screen animated icons created by Freepik - Flaticon</a> */}
                  </div>
                  <h4>Scalability</h4>
                  <div class="module-content">
                    <p>We design your website with scalability in mind, allowing you to easily add new features and functionalities as your business grows.</p>
                    <Link to='/contact-us' className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
                  </div>
                </div>
                <div class="key-module">
                  <div class="key-image-container">
                    <img src="assets/images/peace-red.png" alt="Static" class="static-image" loading='lazy' decoding='async' />
                    <img src="assets/images/space.gif" alt="Animated" class="animated-image" loading='lazy' decoding='async' />
                    {/* <a href="https://www.flaticon.com/free-animated-icons/anti-terrorism-day" title="anti terrorism day animated icons">Anti terrorism day animated icons created by Freepik - Flaticon</a> */}
                  </div>
                  <h4>Peace of Mind</h4>
                  <div class="module-content">
                    <p>We handle all the technical aspects of website design and development, allowing you to focus on running your business.</p>
                    <Link to='/contact-us' className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
                  </div>
                </div>
                <div class="key-module">
                  <div class="key-image-container">
                    <img src="assets/images/support-red.png" alt="Static" class="static-image" loading='lazy' decoding='async' />
                    <img src="assets/images/support.gif" alt="Animated" class="animated-image" loading='lazy' decoding='async' />
                    {/* <a href="https://www.flaticon.com/free-animated-icons/customer-service" title="customer service animated icons">Customer service animated icons created by Freepik - Flaticon</a> */}
                  </div>
                  <h4>Support</h4>
                  <div class="module-content">
                    <p>Don't let the low cost web design fool you. Our team of experienced designers creates visually appealing and user-friendly websites that effectively represent your brand and resonate with your target audience.</p>
                    <Link to='/contact-us' className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* 
       <section className="careers-section">
        <div className="container">
          <div className="careers-container">
            <div className="careers-content">
              <h1>Careers at KumbhaTech</h1>
              <p>Don't let budget constraints limit your online potential. Contact Kumbhatech Solutions today for a free consultation and quote. We'll discuss your needs, answer your questions, and help you craft a website that effectively represents your brand and drives business growth. Let us show you how affordable website design can be powerful and impactful!.</p>
              <div className='service-links' >
                <Link to='/all-services'>View Carrer Page</Link>
              </div>
            </div>
          </div>
        </div>
      </section>  */}
      <section className="upcoming-meeting-client " id="meetings">
        <div className="container">
          <div className='client-heading col-lg-8'>
            <h2>Meet Our Clients</h2>
            <h5>"Discover our affordable website design services at Kumbhatech Solutions. We specialize in creating sleek, user-friendly websites that enhance your online presence. Whether you're a startup or an established business, our expert team delivers tailored solutions to meet your needs. Contact us today to elevate your digital footprint!"</h5>
          </div>
          <br />
          <ClientSlider />
        </div>
      </section>

      <section className="testimonial-section ">
        <div className='container'>


          <div className=' col-lg-12'>

            <h2>What's Our Client Says</h2>

          </div>
          <Testimonial />
        </div>
      </section>


      <section class="kumbha-items-section">
        <div class="container">
          <div class="item-section">
            <div class="item-text">
              <h2>KumbhaTech</h2>
              <p>We offer a full range of services.</p>
            </div>
            <div class="item-button">
              <Link to='/contact-us'>Contact Us</Link>
              <Link to='/how-we-works'>How we Work</Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />

    </Fragment>
  )
}

export default Home;
/* <a href="https://www.freepik.com/free-photo/cute-woman-jumping-isolated-blue-with-copy-space_14565061.htm#fromView=image_search&page=4&position=35&uuid=e9ba25a0-40c1-448d-b853-7a673940d692">Image by freepik</a>abnner */