import React, { Fragment, useState, useRef,useEffect } from 'react'
import Header from '../Header';
import Footer from '../Footer';
import SubNavigation from '../OurServices/SubNav.jsx';
import Slider from 'react-slick';
import AdmissonChoosing from './AdmissonChoosing.jsx';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slider.css';
import { Link } from 'react-router-dom';
const  AdmissonService= () => {
    useEffect(() => {
        document.title = ' Affordable University Admission Counseling | KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    const [activeTab, setActiveTab] = useState('Tab1');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const cards = [
     
        {
            image: "assets/images/enhanced-user.webp",
            heading: "Expertise and Experience",
            description: "University admission consultants bring expertise and experience to the table, offering valuable insights, advice, and recommendations based on their knowledge of the higher education landscape and admissions process.",
          },
          {
            image: "assets/images/Business-web.webp",
            heading: "Personalized Support",
            description: "Consultants provide personalized support and guidance tailored to the individual needs and goals of each student, ensuring that they receive the attention and assistance they need to succeed in their university admission journey.",
          },
          {
            image: "assets/images/stream.webp",
            heading: "Streamlined Process",
            description: "Consultants help streamline the university admission process by providing step-by-step guidance, assistance with application materials, and support with visa and immigration procedures, saving students time and effort.",
          },
          {
            image: "assets/images/maximized.webp",
            heading: "Maximized Opportunities",
            description: "By helping students identify suitable programs and institutions, highlight their strengths and achievements, and present themselves effectively in their application materials, consultants help maximize students' admission opportunities.",
          }
    ];

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };


    return (
        <Fragment>
            <Header />
            <div class="custom-banner">
        <div class="custom-banner-content">
          <h1>Affordable University Admission Counseling for Overseas Students</h1>
          <p>Achieve your dream of studying abroad with Kumbhaatech Solutions. Our expert counselors provide personalized guidance and support throughout your university admission process. Get professional advice and assistance at a price you can afford.</p>
          <br />
          <Link to='/contact-us' className='abt-btn'>Start Your Journey Today!</Link>
        </div>
      </div>

            <section className='subnav'>
                <div className="container" >
                    <div className='row'>
                        <div className="col-lg-12" >

                            <SubNavigation />
                        </div>
                    </div>
                </div>
            </section>
            <section className="web-development-container">
                <div className="container">
                    <div className="row">
                        <div className="web-heading col-lg-9">
                            <h2>Unlocking Your Path to Overseas Education: The Role of University Admission Consulting.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="web-content">
                               <p>Embarking on a journey of overseas education is a life-changing decision that requires careful planning, preparation, and guidance. From selecting the right university to navigating the visa application process, there are numerous challenges and complexities to overcome. That's where university admission consulting services come into play. </p>
                                <p>At KumbhaaTech, we understand the importance of personalized support and guidance for students pursuing education abroad. Our comprehensive consulting services are designed to streamline the university admission process, ensuring that students achieve their academic and career goals with confidence.</p>
                                <Link to='/contact-us' className="btn-learn">Learn More!</Link>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="image-container">
                                <img src="assets/images/education.webp" alt="University Admission Consulting" loading='lazy' decoding='async'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="home-about-section " id='understanding'>
        <div class="container">
          <div className=' home-about-heading col-lg-7'>
            <h2>Kumbhatech Solutions: Your Partner in University Admission Consulting</h2>
          
          </div>
          <div class="home-about-container ">

            <div className='home-about-container-logo'>
            <img src="assets/images/academic-writing.png" alt="Understanding University Admission Consulting" loading='lazy' decoding='async' />
            </div>
            <div className='home-about-container-inside'>
             
            <p>University admission consulting involves providing personalized guidance and support to students seeking admission to universities and colleges abroad. From selecting suitable programs and institutions to preparing application materials and navigating the admissions process, university admission consultants assist students at every step of their journey towards overseas education.</p>
              <button className='main-button align-left'> Get Appoinment Now!</button>

            </div>

          </div>
        </div>
      </section>
            <section className="info-section" id="purpose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">The Purpose of University Admission Consulting</h2>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Program and Institution Selection</h3>
                                    <p>University admission consultants help students identify programs and institutions that align with their academic interests, career goals, and personal preferences.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Application Assistance</h3>
                                    <p>Consultants assist students in preparing and organizing application materials, including transcripts, letters of recommendation, essays, and resumes, to ensure that their applications are complete and competitive.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Visa and Immigration Support</h3>
                                    <p>Consultants provide guidance and assistance with visa and immigration processes, helping students navigate complex requirements and procedures to obtain the necessary documentation for studying abroad.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Financial Planning</h3>
                                    <p>Consultants offer advice and support on financial planning, including tuition fees, living expenses, scholarships, and financial aid options, to help students make informed decisions about funding their education abroad.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">Uses of University Admission Consulting</h2>
                                    <p>University admission consulting services serve various purposes, including:</p>
                                </div>
                            </div>
                            <div className="info-card-with-icon-inner">
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="Application Strategy Development" loading='lazy' decoding='async'/>
                                        </div>
                                        <h5>Application Strategy Development</h5>
                                        <p>Consultants help students develop a comprehensive strategy for applying to universities and colleges abroad, including selecting suitable programs and institutions, preparing application materials, and meeting deadlines.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="Personalized Guidance and Support" loading='lazy' decoding='async'/>
                                        </div>
                                        <h5>Personalized Guidance and Support</h5>
                                        <p>Consultants provide personalized guidance and support tailored to the individual needs and goals of each student, offering advice, feedback, and encouragement throughout the university admission process.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="Maximizing Admission Opportunities" loading='lazy' decoding='async'/>
                                        </div>
                                        <h5>Maximizing Admission Opportunities</h5>
                                        <p>Consultants help students maximize their admission opportunities by identifying suitable programs and institutions, highlighting their strengths and achievements, and presenting themselves effectively in their application materials.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="Reducing Stress and Anxiety" loading='lazy' decoding='async'/>
                                        </div>
                                        <h5>Reducing Stress and Anxiety</h5>
                                        <p>By providing expert guidance and support, consultants help students navigate the university admission process with confidence, reducing stress and anxiety associated with applying to schools abroad.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='benefit-slider-section' id='benefits'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="benifit-heading">
                                <h2>Benefits of University Admission Consulting</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="slider-container">
                                <Slider ref={sliderRef} {...settings}>
                                    {cards.map((card, index) => (
                                        <div className="slider-card" key={index}>
                                            <img src={card.image} alt={card.heading} className="card-image" loading='lazy' decoding='async'/>
                                            <h4 className="card-heading">{card.heading}</h4>
                                            <p className="card-description">{card.description}</p>
                                            <Link to='/contact-us' className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="bottom-container">
                                    <div className="custom-arrows">
                                        <button className="custom-arrow" onClick={handlePrevious}>
                                            &#8249;
                                        </button>
                                        <button className="custom-arrow" onClick={handleNext}>
                                            &#8250;
                                        </button>

                                    </div>
                                    <div className='service-links'>
                                    <Link to='/contact-us'>Connect With Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>Get expert guidance on academic admissions with KumbhaaTech Solutions. We provide support in crafting compelling applications to enhance your chances of success.</p>
                                       
                                    </div>
                                  
                                </div>

                            </div>
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>We believe that every business deserves the opportunity to thrive in the digital world, regardless of their financial constraints.</p>
                                       
                                    </div>
                                    <br/>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className='fixed-bacground-section'>
                <div className="fixed-background-2">
                    <div className="fixed-background-content">
                        <div className="fixed-background-content-inside">
                            <h2>Importance of University Admission Consulting</h2>
                            <p>In the competitive landscape of overseas education, university admission consulting services play a crucial role in helping students navigate the complexities of the admissions process and achieve their academic and career goals. By providing personalized guidance, support, and expertise, consultants help students make informed decisions, maximize their admission opportunities, and succeed in their university admission journey.</p>
                           
                           <br/> 
                        
                        </div>
                    </div>
                </div>
            </section>
           {/*  <section className="apply-now" id="apply">
                <div className='apply-image-container'>
                    <div className="container" >
                        <div className='col-lg-6 '>
                            <h2 style={{ paddingTop: '30px' }}>KumbhaaTech’s Approach to Cheap SOP Writing Services for Students</h2>
                            <p style={{ color: 'white' }}>At KumbhaaTech, we understand the importance of cost-effective solutions that deliver results. Our approach to SOP writing services for students focuses on:</p>
                        </div>
                        <div className="row" >

                            <AdmissonChoosing />

                        </div>
                    </div>
                </div>
            </section>
           */}

           {/*  <section className="conclusion">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='conclusion-heading'>conclusion</h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="fist-conclusion-content">
                                <p>In conclusion, personalized SOP writing plays a crucial role in the success of students applying for overseas education, both in terms of university admission and visa approval. From highlighting academic achievements and career aspirations to demonstrating fit and suitability for a particular program or institution, SOPs provide students with an opportunity to present themselves in the best possible light. Cheap SOP writing services offer students a cost-effective solution to their writing needs, ensuring accessibility, affordability, and quality without compromise. Contact KumbhaaTech today to learn more about our cheap SOP writing services and take the first step towards achieving your academic and professional goals.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="second-conclusion-content">
                                <p>By choosing KumbhaaTech, you're partnering with a trusted SOP writing service provider that prioritizes affordability, quality, and reliability. Let us help you craft a compelling and persuasive SOP that sets you apart from the competition and paves the way for your success.</p>
                                <Link className='conclusion-link'>Talk To Expert </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            
            <section className='news-letter'>
                <div className="news-letter-container">

                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div>
                                    <h4>Talk to the Kumbha Tech Exper Team</h4>
                                    <p>For our Abortable academic writing  service.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='subscribe-btnbox'>
                                    <Link to='/contact-us'>Connect With Us <span>+</span></Link></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </Fragment>
    )
}

export default AdmissonService;
