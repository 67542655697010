import React, { useRef } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

function TeamSlider() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const team = [
    {
      image: "assets/images/kumbha logo.png",
      heading: "Hariharan ",
      role:"Project Head",
      description: " bringing a wealth of experience and a keen eye for detail to the team."
    },
    {
      image: "assets/images/kumbha logo.png",
      heading: "Iniya H",
      role:'Senior Back End developer',
      description: "A technical wizard, Iniya specializes in developing Back-End using Php and MySql."
    },
    {
      image: "assets/images/kumbha logo.png",
      heading: "Prakash",
      role:'Senior React JS Developer',
      description: "A technical wizard, Prakash specializes in developing dynamic and responsive websites using React JS."
    },
    {
      image: "assets/images/kumbha logo.png",
      heading: "Thejasri",
      role:"Senior Writer",
      description: "An expert wordsmith, Thejasri crafts engaging and SEO-friendly content that resonates with your audience."
    },
    {
      image: "assets/images/kumbha logo.png",
      heading: "Menaka",
      role:"Senior Digital Marketing and Video Editing",
      description: "Menaka's expertise in digital marketing and video editing helps amplify your brand's reach and impact."
    }
  ];

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {team.map((card, index) => (
          <div className="slider-card team" key={index}>
            <img src={card.image} alt={card.heading} className="card-image" />
            <h3 className="card-heading">{card.heading}</h3>
            <p className="card-description">{card.role}</p>
            <p className="card-description">{card.description}</p>
            <Link to="/some-route" className="explore-button">
              <span className="explore-texts">LinkedIN</span>
              <span className="arrows">&rarr;</span>
            </Link>
          </div>
        ))}
      </Slider>
      <div className="bottom-container">
        <div className="custom-arrows">
          <button className="custom-arrow" onClick={handlePrevious}>
            &#8249;
          </button>
          <button className="custom-arrow" onClick={handleNext}>
            &#8250;
          </button>
        </div>
      </div>
    </div>
  );
}

export default TeamSlider;
