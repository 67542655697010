import React, { useRef } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Link } from "react-router-dom";

function WebSlider() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  };

  const cards = [
    {
      image: "assets/images/started-web.webp",
      heading: "Starter Websites",
      description: "Ideal for small businesses and startups, our starter websites provide a basic online presence with essential features like contact information, an about us section, and service offerings.",
     
    },
    {
      image: "assets/images/Business-web.webp",
      heading: "Business Websites",
      description: "Businesses utilize websites to showcase products, services, and company information, attracting potential customers and driving sales.",
     
    },
    {
      image: "assets/images/Ecommerse-web.webp",
      heading: "E-commerce Websites",
      description: "Take your business online with an affordable e-commerce website solution. We design and develop user-friendly platforms for selling your products online, equipped with secure payment gateways and efficient product management systems.",
    
    },
    {
      image: "assets/images/customized.webp",
      heading: "Custom Websites",
      description: "For unique business needs, we offer custom website development solutions tailored to your specific requirements and functionalities.",
      
    }
  ];

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {cards.map((card, index) => (
          <div className="slider-card" key={index}>
            <img src={card.image} alt={card.heading} className="card-image" loading="lazy" decoding="async"/>
            <h3 className="card-heading">{card.heading}</h3>
            <p className="card-description">{card.description}</p>
            <Link to='/affordable-website-design-services' className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
          </div>
        ))}
      </Slider>
     <div className="bottom-container">
      <div className="custom-arrows">
        <button className="custom-arrow" onClick={handlePrevious}>
        &#8249;
        </button>
        <button className="custom-arrow" onClick={handleNext}>
        &#8250;
        </button>
        
        </div>
        <div className='service-links '>
          <Link to='/all-services' style={{color:'white'}}>All Our Services</Link>
        </div>
      </div>
    </div>
  );
}

export default WebSlider;
