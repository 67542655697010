import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs'
import Services from './Components/Services';
import Project from './Components/Projects';
import WebDevelopment from './Components/OurServices/WebDevelopment';
import ContentWriting from './Components/ContentWritingService/ContentWriting'
import SoftwareDevelopment from './Components/SoftwareDevelopmentService/SoftwareDevelopment';
import DigitalMarketing from './Components/DigitalMarketingService/DigitalMarketing';
import DomineRegistration from './Components/DomineRegistration/DomineRegistration';
import SeoService from './Components/SeoService/SeoService';
import Blog from './Components/Blog';
import AcademicWriting from './Components/AcademicWriting/AcademicWriting'
import HowWeWork from './Components/HowWeWork';
import Careers from './Components/Career';
import SopWriting from './Components/SopWriting/SopWriting';
import AdmissionService from './Components/AdminssionService/AdmissonService';
import WebHosting from './Components/WebHosting/WebHosting';

import Telemarketing from './Components/telemarketing/Telemarketing';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/services' element={<Services />} />
          <Route path='/projects' element={<Project />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/affordable-website-design-services' element={<WebDevelopment />} />
          <Route path='/affordable-professional-software-services' element={<SoftwareDevelopment />} />
          <Route path='/affordable-expert-content-writing' element={<ContentWriting />} />
          <Route path='/affordable-strategic-digital-marketing' element={<DigitalMarketing />} />
          <Route path='/domine-registration-services' element={<DomineRegistration />} />
          <Route path='/effective-seo-services' element={<SeoService />} />
          <Route path='/academic-writing-services' element={<AcademicWriting />} />
          <Route path='/how-we-works' element={<HowWeWork />} />
          <Route path='/sop-writing-services' element={<SopWriting />} />
          <Route path='/university-admission-services' element={<AdmissionService />} />
          <Route path='/web-hosting-services' element={<WebHosting />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/career' element={<Careers />} />
          <Route path='/telemarketing' element={<Telemarketing/>} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
