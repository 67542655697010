import React, { useState, useEffect } from 'react';
import './Testimonial.css';

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoSliding, setIsAutoSliding] = useState(true);
  const [progress, setProgress] = useState(0);

  const testimonials = [
    {
      name: "Saravanan",
      text: "Excellent website design team. Kumbha Technologies is adept at developing pleasing-to-the-eye and user-friendly websites. They gave me complete satisfaction throughout my project.",
      designation: "Janani Global Impex Website",
    },
    {
      name: "Hakkim",
      text: "Superb customer service. I asked for a completely new website. At the first meeting, I was sure that Kumbha Tech would suit my needs at reasonable prices. I want to express my sincere gratitude to the entire Kumbha Tech team. They have provided excellent customer service and have paid great attention to detail.",
      designation: "Nourishment for Needy - NGO website",
    },
    {
      name: "Saleem",
      text: "I am overjoyed with the assistance I got from your content writers. Eventually, it is worth for money. I am delighted with your work. Thank you.",
      designation: "Yexis Solution - Website Content Writing",
    },
    {
      name: "Saleem",
      text: "Make it Visually Engaging Well-written testimonials create a picture with words so that the reader can understand the value of your product or service before purchasing from you. It’s important to post descriptive, detailed, enthusiastic language that helps convince your prospects to make a purchase. To make the testimonial more effective, include visual elements like images, videos, and social media feeds. Adding these components will show readers that the testimonial is from a real person.",
      designation: "Yexis Solution - Website Content Writing",
    }
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    let interval;
    if (isAutoSliding) {
      interval = setInterval(() => {
        nextSlide();
      }, 6000); 
    }

    return () => clearInterval(interval);
  }, [isAutoSliding]);

  useEffect(() => {
    setProgress(((currentIndex + 1) / testimonials.length) * 100);
  }, [currentIndex]);

  return (
    <div>
      <div className="testimonial-slider">
        <div className="testimonial-slide">
          <i className="fa fa-quote-right" aria-hidden="true"></i>
          <p className="testimonial-text">{testimonials[currentIndex].text}</p>
          <h3 className="testimonial-name">{testimonials[currentIndex].name}</h3>
          <p className="testimonial-designation">{testimonials[currentIndex].designation}</p>
        </div>

      </div>
      <div className='testimonial-buttom-button'>
      <div className="test-button-container">
          <button className="test-prev-button" onClick={prevSlide}>
            &lt;
          </button>
          <button className="test-next-button" onClick={nextSlide}>
            &gt;
          </button>
        </div>
      <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
       
      </div>
    </div>
  );
};

export default TestimonialSlider;
